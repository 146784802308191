import React from "react"
import styled from "styled-components"
import { DawaColors } from "../../themes/Colors"
import media from "../../themes/media"

import testimonial1 from "../../images/landing/testimonial/ASCOVIME - dawaCoordinator Registering Patients 2_2019.jpg"
import testimonial2 from "../../images/landing/testimonial/ASCOVIME - DawaCoordinator Registers Patient 2_2019.jpg"
import testimonial3 from "../../images/landing/testimonial/ASCOVIME - Pupil Receive School Items as Gifts 2_2019 1.jpg"

const StyledTestimonialsSection = styled.section`
  padding: 32px 10px;
  ${media.tablet`
    padding: 32px 150px;
  `};
`

const SectionWrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
`
const ColWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Header = styled.h3`
  font-size: 50px;
  font-weight: bold;
  color: ${DawaColors.black};
  text-align: center;
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Row = styled.div`
  ${media.tablet`
    display: flex;
  `};
`
const Col = styled.div`
  position: relative;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  margin: 24px 12px;
  color: ${DawaColors.white};
  ${media.tablet`
    width: 33%;
    flex-direction: row;
    margin: 12px;
  `};
  &:hover,
  :focus,
  :active {
    div {
      opacity: 0.8;
    }
  }
`
const Overlay = styled.div`
  position: absolute;
  padding: 24px;
  text-align: center;
  overflow-y: overlay;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: bold;
  p {
    margin-top: 10px;
    font-size: 16px;
    font-weight: normal;
  }
`
const Testimonials = () => {
  return (
    <StyledTestimonialsSection>
      <SectionWrapper>
        <Header>Testimonials</Header>
        <Row>
          <Col>
            <Image src={testimonial1} />
            <Overlay>
              Ettiene - University of Buea Student
              <p>
                “This is my first ever follow up appointment I have attended
                thanks to dawaCare and it’s free. Now the doctor has checked me
                again and made sure I am recovering as planned. I feel much
                better now”
              </p>
            </Overlay>
          </Col>
          <Col>
            <Image src={testimonial2} />
            <Overlay>
              Ms Elodi - Proud Nursing Mother
              <p>
                “dawaCare helped me alot when I was pregnant, with reminders on
                appointments, vaccines, medicine and advice when I did not know
                what to do, when I had difficulties during my pregnancy”.
              </p>
            </Overlay>
          </Col>
          <Col>
            <Image src={testimonial3} />
            <Overlay>
              Ms Atem Delphine - Expectant Mother
              <p>
                "Keven has been so good, they took care of me so well, in fact
                so so well, I was so happy with them, I can’t thank them enough.
                I really appreciate their work”.
              </p>
            </Overlay>
          </Col>
        </Row>
      </SectionWrapper>
    </StyledTestimonialsSection>
  )
}

export default Testimonials
