import React from "react"
import styled from "styled-components"
import { DawaColors } from "../../themes/Colors"
import media from "../../themes/media"
import Button from "../Button"

import partner1 from "../../images/partners/ASCOVIME.jpeg"
import partner2 from "../../images/partners/zahn.jpg"
import partner3 from "../../images/partners/logo-minsante.png"
import partner4 from "../../images/partners/St. Veronica Logo.png"

const Image = styled.img`
  display: block;
  width: 300px;
  height: 100%;
  object-fit: contain;
`
const StyledPartnersSection = styled.section`
  ${media.tablet`
    padding: 32px 150px 100px;
  `};
  padding: 32px 10px;
  background: ${DawaColors.white};
`

const SectionWrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
`
const Col = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${DawaColors.white};
  ${media.tablet`
    flex-direction: row;
    width: ${props => props.width || "25%"};
    margin: 12px;
  `};
`
const Row = styled.div`
  ${media.tablet`
    display: flex;
  `};
`
const Header = styled.h3`
  font-size: 50px;
  font-weight: bold;
  color: ${DawaColors.black};
  text-align: center;
`
const Partners = () => {
  return (
    <StyledPartnersSection>
      <SectionWrapper>
        <Header>Partners</Header>
        <Row>
          <Col>
            <a href="https://www.ascovime.org" target="_blank">
              <Image src={partner1} />
            </a>
          </Col>
          <Col>
            <a href="http://www.zahncenternyc.com" target="_blank">
              <Image src={partner2} />
            </a>
          </Col>
          <Col width="22%">
            <a href="https://www.minsante.cm" target="_blank">
              <Image src={partner3} />
            </a>
          </Col>
          <Col width="14%">
            <a href="https://www.biakahc.org" target="_blank">
              <Image src={partner4} />
            </a>
          </Col>
        </Row>
      </SectionWrapper>
    </StyledPartnersSection>
  )
}

export default Partners
