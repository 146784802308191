import React from "react"
import styled from "styled-components"
import { DawaColors } from "../../themes/Colors"
import media from "../../themes/media"
import Button from "../Button"

const StyledImpactSection = styled.section`
  ${media.tablet`
    padding: 32px 150px 100px;
  `};
  padding: 32px 10px;
  background: ${DawaColors.blue};
`

const SectionWrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12px;
  text-align: center;
  color: ${DawaColors.white};
  ${media.tablet`
    width: 33%;
  `};
`
const ButtonCol = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  color: ${DawaColors.white};
  ${media.tablet`
    margin-top: 100px;
    flex-direction: row;
  `};
`
const Row = styled.div`
  margin-top: 50px;
  ${media.tablet`
    align-items: start;
    margin-top: 50px;
    display: flex;
  `};
`
const Header = styled.h3`
  font-size: 50px;
  font-weight: bold;
  color: ${DawaColors.white};
  text-align: center;
`
const StyledHref = styled.a`
  text-decoration: none;
  color: inherit;
`
const ActionButton = styled(Button)``
const Statistic = styled.span`
  font-size: 48px;
  font-weight: bold;
`
const Description = styled.p`
  font-size: 15px;
`
const Impact = () => {
  return (
    <StyledImpactSection>
      <SectionWrapper>
        <Header>Impact</Header>
        <Row>
          <Col>
            <Statistic>7000</Statistic>
            <br></br>
            <Description>Patient/Pregnant Mothers Served</Description>
          </Col>
          <Col>
            <Statistic>140,000</Statistic>
            <br></br>
            <Description>Over SMS and Voice Interactions</Description>
          </Col>
          <Col>
            <Statistic>70%</Statistic>
            <br></br>
            <Description>Improvement in Appointment Adherence</Description>
          </Col>
        </Row>
        <ButtonCol>
          <ActionButton
            type="secondary"
            label={
              <StyledHref
                href="https://5a98f9dd-c21f-4354-949d-210e3ed6f36e.filesusr.com/ugd/2a547b_70cb804e21034089a6ec2c2bddda3a6d.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Annual Report
              </StyledHref>
            }
          />
        </ButtonCol>
      </SectionWrapper>
    </StyledImpactSection>
  )
}

export default Impact
