import React from "react"
import styled from "styled-components"
import { HeroImage } from "./heroimage"

import { DawaColors } from "../themes/Colors"
import media from "../themes/media"

const StyledHeroContainer = styled.section`
  padding: 150px 10px 0;
  max-width: 1200px;
  margin: 0 auto;
  background: ${DawaColors.white};
  ${media.tablet`
    padding: 170px 20px 50px;
  `};
`
const HeroWrapper = styled.div`
  ${media.tablet`
    padding: 20px 0;
  `};
`
const ColWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  ${media.tablet`
    flex-direction: row;
  `};
`
const HeroTextContainer = styled.div`
  color: ${DawaColors.black};
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  padding: 55px 10px;
  ${media.tablet`
    width: 50%;
    font-size: 22px;
  `};
`
const HeroImageContainer = styled.div`
  ${media.tablet`
    width: 75%;
  `};
  width: 100%;
`

const Hero = () => {
  return (
    <StyledHeroContainer>
      <HeroWrapper>
        <ColWrapper>
          <HeroTextContainer>
            <p>
              dawaCare’s mission is to empower patients with healthier lives.
            </p>
            <p>
              We accomplish this mission <strong>together</strong> with our
              stakeholders by researching, designing, developing and
              implementing IT based communications solutions that meet specific
              needs of our partner health facilities and the patients they
              serve.
            </p>
          </HeroTextContainer>
          <HeroImageContainer>
            <HeroImage />
          </HeroImageContainer>
        </ColWrapper>
      </HeroWrapper>
    </StyledHeroContainer>
  )
}

export default Hero
