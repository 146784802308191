import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const StyledImg = styled(Img)`
  max-width: 800px;
  max-height: 100%;
`

const HeroImage = () => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(relativePath: { eq: "hero.png" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Link to="/">
        <StyledImg fluid={data.hero.childImageSharp.fluid} />
      </Link>
    )}
  />
)

export { HeroImage }
