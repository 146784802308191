import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"
import WhatWeDo from "../components/WhatWeDo"
import HowItWorks from "../components/HowItWorks"
import Impact from "../components/Impact"
import Testimonials from "../components/Testimonials"
import Partners from "../components/Partners"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <HowItWorks />
    <WhatWeDo />
    <Impact />
    <Testimonials />
    <Partners />
  </Layout>
)

export default IndexPage
