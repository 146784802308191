import React from "react"
import styled from "styled-components"
import { DawaColors } from "../../themes/Colors"
import media from "../../themes/media"

import howItWorks1 from "../../images/landing/what_we_do/Street Pharmacy - Village Douala.jpg"
import howItWorks2 from "../../images/landing/what_we_do/Vaccinating Child.jpg"
import howItWorks3 from "../../images/landing/what_we_do/St. Veronica - dawaCoordinator with Patients on ANC 1.jpg"

const StyledHowItWorksSection = styled.section`
  padding: 32px 10px;
  ${media.tablet`
    padding: 32px 150px 100px;
  `};
  background: ${DawaColors.blue};
`
const SectionWrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  background: ${DawaColors.blue};
`
const Row = styled.div`
  ${media.tablet`
    display: flex;
  `};
`
const Col = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 12px;
  color: ${DawaColors.white};
  ${media.tablet`
    width: 33%;
    flex-direction: row;
    margin: 12px 35px;
  `};
  &:hover,
  :focus,
  :active {
    div {
      opacity: 0.8;
    }
  }
`
const Overlay = styled.div`
  position: absolute;
  padding: 24px;
  text-align: center;
  overflow-y: overlay;
  top: 44px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: bold;
  p {
    margin-top: 10px;
    font-size: 16px;
    font-weight: normal;
  }
`
const Header = styled.h3`
  font-size: 50px;
  font-weight: bold;
  color: ${DawaColors.white};
  text-align: center;
`
const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`
const OverlayLabel = styled.div`
  display: block;
  font-size: 28px;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 20px;
`
const HowItWorks = () => {
  return (
    <StyledHowItWorksSection>
      <SectionWrapper>
        <Header>How It Works</Header>
        <Row>
          <Col>
            <OverlayLabel>Facility Research</OverlayLabel>
            <Image src={howItWorks1}></Image>
            <Overlay>
              <p>
                During the initial phase, dawaCare’s team will conduct pre-pilot
                research by gathering baseline data of your facility. We do this
                through interviews with doctors, nurses, allied health workers,
                administration and patients. We also take a deep look at current
                hospital data to get insights to tailor/custom the services to
                meet your specific needs.
              </p>
            </Overlay>
          </Col>
          <Col>
            <OverlayLabel>Pilot Phase</OverlayLabel>
            <Image src={howItWorks2}></Image>
            <Overlay>
              <p>
                In the pilot phase, we would determine together with hospital
                administration which departments we would pilot in. This is a 3
                - 6 month feasibility trial. This phase is critical in
                determining if the services will be viable and sustainable in
                the long run and be beneficial to all stakeholders.
              </p>
            </Overlay>
          </Col>
          <Col>
            <OverlayLabel>Partnership Phase</OverlayLabel>
            <Image src={howItWorks3}></Image>
            <Overlay>
              <p>
                After a pilot phase, if the services are determined to be
                viable, sustainable and beneficial to all stakeholders, we would
                then establish a long term relationship with the facility for
                the enjoyment of the patients and hospital staff.
              </p>
            </Overlay>
          </Col>
        </Row>
      </SectionWrapper>
    </StyledHowItWorksSection>
  )
}

export default HowItWorks
